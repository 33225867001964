<template>
  <div class="no-data-component">
    {{ $t("g.noData") }}
  </div>
</template>
<script>
export default {
  name: "NoDataComponent",
};
</script>
<style>
@import "./_index.scss";
</style>
